import React from "react";

export default class HeaderBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tokenCount: 0,
    };
    this.app = window.AppInstance;
  }
  render() {
    return (<div className="p-2 text-right">
      </div>);
  }
}