import BaseApp from "./baseapp";
import {
  MainApp,
} from "./mainapp";
import {
  onSnapshot,
  getFirestore,
  collection,
  orderBy,
  query,
  limit,
  where,
} from "firebase/firestore";
import {
  getIdToken,
  getAuth,
} from "firebase/auth";

export default class JournalManager {
  app: MainApp;
  loaded = false;
  journalId = "";
  cachedJournalId = "";
  entryId = "";
  listCache = "";
  paginationPageIndex = 0;
  pageSize = 8;
  journalSubscription: any = null;
  entriesSubscription: any = null;
  journalCache: any = {};
  entryCache: any = {};
  journalsSnapshot: any = null;
  entriesSnapshot: any = null;
  handleJournalFeedCallback: any = null;
  journalFeedView: HTMLDivElement = document.createElement("div");
  entryFeedView: HTMLDivElement = document.createElement("div");
  tagDataList: HTMLDataListElement = document.createElement("datalist");
  tagSelectList: HTMLSelectElement = document.createElement("select");
  journalSelectList: HTMLSelectElement = document.createElement("select");
  entryPagingView: HTMLDivElement = document.createElement("div");
  entriesCountView: HTMLDivElement = document.createElement("div");
  getJournalCard: any = null;
  getEntryCard: any = null;
  tagFilter: string = "";

  constructor(app: MainApp) {
    this.app = app;
  }
  async load(reload = false) {
    if (this.loaded && !reload) return;
    this.loaded = true;
    this.cachedJournalId = "";
    if (this.journalSubscription) this.journalSubscription();

    const journalFeedQuery = this.getJournalQuery();
    this.journalSubscription = onSnapshot(journalFeedQuery, (snapshot: any) => this.handleJournalFeed(snapshot));
  }
  getJournalQuery() {
    const journalFeedRef = collection(getFirestore(), "Journals");
    return query(journalFeedRef, orderBy("users." + this.app.uid + ".accessLevel"),
      where("users." + this.app.uid + ".accessLevel", ">", 0),
      limit(500));
  }
  getEntryQuery() {
    const journalFeedRef = collection(getFirestore(), "Journals/" + this.journalId + "/entries");
    if (!this.tagFilter || this.tagFilter === "All") {
      return query(journalFeedRef, limit(500));
    } else {
      return query(journalFeedRef, where("entryTags", "array-contains", this.tagFilter),
        limit(500));
    }
  }
  handleJournalFeed(snapshot: any = null): void {
    if (snapshot) this.journalsSnapshot = snapshot;
    this.journalFeedView.innerHTML = "";
    let selectedIdFound = false;
    let firstId = "";
    this.journalCache = {};
    if (!this.journalsSnapshot) return;
    this.journalsSnapshot.forEach((doc: any) => {
      if (!firstId) firstId = doc.id;
      this.journalCache[doc.id] = doc.data();
      if (this.getJournalCard) {
        const card = this.getJournalCard(doc);
        this.journalFeedView.appendChild(card);
      }
      if (doc.id === this.journalId) selectedIdFound = true;
    });
    this.journalFeedView.querySelectorAll(".journal_list_item").forEach((el: any) => {
      el.addEventListener("click", async (e: any) => {
        e.preventDefault();
        this.journalId = el.dataset.id;
        this.handleJournalFeed();
      });
    });
    if (!selectedIdFound && firstId) {
      this.journalId = firstId;
      return this.handleJournalFeed();
    }

    this.updateTagList();
    this.updateJournalSelect();
    this.updateEntriesSubscription();
    if (this.handleJournalFeedCallback) this.handleJournalFeedCallback();
  }
  updateJournalSelect() {
    this.journalSelectList.innerHTML = "";
    Object.keys(this.journalCache).forEach((key: any) => {
      const data = this.journalCache[key];
      const option = document.createElement("option");
      option.value = key;
      option.text = data.journalName;
      this.journalSelectList.appendChild(option);
    });
    this.journalSelectList.value = this.journalId;
  }
  async saveJournalField(field: string, value: any) {
    const token = await getIdToken(<any>getAuth().currentUser);
    const response = await fetch(this.app.basePath + "journalAPI/updateOwnerData", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        token,
      },
      body: JSON.stringify({
        [field]: value,
        journalId: this.journalId,
      }),
    });
    const json = await response.json();
    if (!json.success) {
      alert(json.errorMessage);
    }
  }
  updateEntriesSubscription() {
    if (this.cachedJournalId === this.journalId) return;
    this.cachedJournalId = this.journalId;

    if (this.entriesSubscription) this.entriesSubscription();

    if (!this.journalId) {
      this.entryFeedView.innerHTML = "";
      return;
    }
    const entriesQuery = this.getEntryQuery();
    this.entriesSubscription = onSnapshot(entriesQuery, (snapshot: any) => this.handleEntriesFeed(snapshot));
  }
  handleEntriesFeed(snapshot: any = null): void {
    if (snapshot) this.entriesSnapshot = snapshot;
    const existingItems = this.entryFeedView.querySelectorAll(".journal_entry_list_item");
    const cardsMap: any = {};
    Array.from(existingItems).forEach((el: any) => {
      cardsMap[el.dataset.id] = el;
    });
    if (!this.entriesSnapshot) return;
    this.entryCache = {};
    this.entriesSnapshot.forEach((doc: any) => {
      const data = doc.data();
      this.entryCache[doc.id] = data;
      if (this.getEntryCard && !cardsMap[doc.id]) {
        const card = this.getEntryCard(doc);

        this.entryFeedView.appendChild(card);
      } else {
        delete cardsMap[doc.id];
      }
    });
    Object.keys(cardsMap).forEach((key: any) => {
      cardsMap[key].remove();
    });

    this.entryFeedView.querySelectorAll(".journal_entry_list_item .delete_button").forEach((el: any) =>
      el.addEventListener("click", async (e: any) => {
        el.closest(".journal_entry_list_item").remove();
        e.preventDefault();
        const token = await getIdToken(<any>getAuth().currentUser);
        const response = await fetch(this.app.basePath + "journalAPI/deleteEntry", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            token,
          },
          body: JSON.stringify({
            journalId: this.journalId,
            entryId: el.dataset.id,
          }),
        });
        const json = await response.json();
        console.log(json);
      }));

    this.updateEntryPaging();
    this.updateEntriesCountFromFirestore();
  }
  updateEntryPaging() {
    const itemKeys = Object.keys(this.entryCache);
    const currentIndex = itemKeys.indexOf(this.entryId);
    this.entryPagingView.innerHTML = BaseApp.generatePagination(itemKeys.length, currentIndex, this.pageSize, this.paginationPageIndex);
  }
  updateTagList() {
    let html = "";
    const journalData = this.journalCache[this.journalId];
    if (journalData?.tagCounts) {
      Object.keys(journalData.tagCounts).forEach((tag: string) => {
        html += `<option>${tag}</option>`;
      });
    }
    this.tagDataList.innerHTML = html;
    this.tagSelectList.innerHTML = "<option>All</option>" + html;
    this.tagSelectList.value = this.tagFilter;
    if (this.tagSelectList.selectedIndex === -1) this.tagSelectList.selectedIndex = 0;
    this.tagFilter = this.tagSelectList.value;
  }
  async addJournal(journalName: string) {
    const token = await getIdToken(<any>getAuth().currentUser);
    const response = await fetch(this.app.basePath + "journalAPI/add", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        token,
      },
      body: JSON.stringify({
        journalName,
      }),
    });
    const json = await response.json();
    console.log(json);
  }
  /** */
  async updateEntriesCountFromFirestore() {
    this.entriesCountView.innerText = Object.keys(this.entryCache).length + " entries";
  }
  async saveJournalEntry(entryText: string, entryTags: string[], entryMeta: any) {
    const token = await getIdToken(<any>getAuth().currentUser);
    const response = await fetch(this.app.basePath + "journalAPI/addEntry", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            token,
        },
        body: JSON.stringify({
            entryText,
            entryTags,
            entryMeta,
            journalId: this.journalId,
        }),
    });
    const json = await response.json();
    return json;
}
}